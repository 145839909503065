import React, {useState} from 'react';
import styled from 'styled-components'
import {BtnIntro} from '../ui'
import FormCotizador from '../forms/formCotizador';
import FormCotizadorPS from '../forms/formCotizadorPS'
import FormCotizadorIS from '../forms/formCotizadorIS'
import FormCotizadorA from '../forms/formCotizadorA';
import FormCotizadorCS from '../forms/formCotizadorCS';
import FormCotizadorC from '../forms/formCotizadorC';
import FormCotizadorIL from '../forms/formCotizadorIL';


const BackPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba( 255, 255, 255, .85);  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  cursor: pointer;
  h4{
    color: #ff5c3e;
    font-size: 1.4em;
    text-align: center;
  }
  p{
      margin: 0;
  }
  @media (min-width: 768px) {
    h4{
      text-align: left;
    }
  }
`;

const PopUpStyle = styled.div`
    position: relative;
    background-color: #f6f6f6;
    height: auto;
    width: auto;
    width: 95vw;
    height: 80vh;
    margin: auto;
    overflow-y: scroll;
    z-index: 9999;
    &::-webkit-scrollbar{
        width: 10px; 
        height: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: #77b843;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:active {
        background-color: #ffffff;
    }
    ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 4px;
    }
    @media (min-width: 768px) {
        padding: 0em;
        width: 60vw;
        height: 80vh;
        font-size: 1em; 
    }
`;

const CloseStyle = styled.div`
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: #77b843;
    padding: 10px 20px;
    border-radius: 4px;
    p{
        color: #ffffff !important;
        font-weight: 600;
        margin: 0;
    }
`;

const BtnCotizador = ({servicio}) => {
    const [ popUp, setpopUp] = useState(false);
    console.log(servicio);

    return ( 
        <div>            
            <a
                onClick={()=> setpopUp(true)}
                href='#!'                    
            >
                <BtnIntro>
                    Cotizar
                </BtnIntro>
            </a>                        
            {
                popUp ? 
                    <BackPopUp>
                        <div>
                            <PopUpStyle>
                                <CloseStyle onClick={()=> setpopUp(false)}>
                                    <p>X</p>
                                </CloseStyle>
                                { servicio === 'principal' ? <FormCotizador/> : null }
                                { servicio === 'paneles-solares' ? <FormCotizadorPS/> : null }
                                { servicio === 'ingenieria-especializada' ? <FormCotizadorIS/> : null }                                
                                { servicio === 'albercas' ? <FormCotizadorA/> : null }
                                { servicio === 'calentadores-solares' ? <FormCotizadorCS/> : null }
                                { servicio === 'calderas' ? <FormCotizadorC/> : null }
                                { servicio === 'iluminacion-led' ? <FormCotizadorIL/> : null }
                            </PopUpStyle>
                        </div>
                    </BackPopUp>
                : null 
            }
        </div>
     );
}
 
export default BtnCotizador;