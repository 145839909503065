import React from 'react';
import styled from 'styled-components'
import { InputStyle, CheckGeneral, CheckLabel, SendStyle} from '../ui'

const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizadorIS = () => {
    
    const currentURL = 'utm'

    return ( 
        <SectionStyle>
            <h2>Ingeniería<br></br> Especializada</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/ingenieria.php"
            >
                <InputStyle
                    type='text'
                    placeholder='Nombre*'
                    id="nombre"
                    name="nombre"
                    placeholder="Nombre*"
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Particular o empresa'
                        id="tipo"
                        name="tipo"
                    />  
                </GridRow>
                <br></br>
                <p>¿En que tema estas más interesado?</p>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="analisis" 
                        value="Análisis de redes eléctricas para perfiles de consumo, calidad de energía y ahorro de energía."
                        name="analisis"
                    />                                   
                    <CheckLabel htmlFor="analisis">Análisis de redes eléctricas para perfiles de consumo, calidad de energía y ahorro de energía.</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="estudios" 
                        value="Estudios de corto circuito y coordinació de protecciones."
                        name="estudios"
                    />                                   
                    <CheckLabel htmlFor="estudios">Estudios de corto circuito y coordinació de protecciones.</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="proteccion" 
                        value="Diseño en sistemas de protección contra descargas atomosféricas (pararrayos)"
                        name="proteccion"
                    />                                   
                    <CheckLabel htmlFor="proteccion">Diseño en sistemas de protección contra descargas atomosféricas (pararrayos)</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="puesta" 
                        value="Diseño de sistemas de puesta a tierra"
                        name="puesta"
                    />                                   
                    <CheckLabel htmlFor="puesta">Diseño de sistemas de puesta a tierra</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="calibracion" 
                        value="Calibración, mantenimiento y pruebas contra descargas atmosféricas (parayos)"
                        name="calibracion"
                    />                                   
                    <CheckLabel htmlFor="calibracion">Calibración, mantenimiento y pruebas contra descargas atmosféricas (parayos)</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="pruebas" 
                        value="Pruebas eléctricas a equipo primario"
                        name="pruebas"
                    />                                   
                    <CheckLabel htmlFor="pruebas">Pruebas eléctricas a equipo primario</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="aceite" 
                        value="Análisis de aceite dieléctrico"
                        name="aceite"
                    />                                   
                    <CheckLabel htmlFor="aceite">Análisis de aceite dieléctrico</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="mantenimiento" 
                        value="Mantenimiento a subestaciones, transformadores, e instalación eléctrica en general"
                        name="mantenimiento"
                    />                                   
                    <CheckLabel htmlFor="mantenimiento">Mantenimiento a subestaciones, transformadores, e instalación eléctrica en general</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="proyectos" 
                        value="Proyectos de instalaciones eléctricas, memorias de cálculo y esquemas de control"
                        name="proyectos"
                    />                                   
                    <CheckLabel htmlFor="proyectos">Proyectos de instalaciones eléctricas, memorias de cálculo y esquemas de control</CheckLabel>
                </div>                
                <br></br>
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
        </SectionStyle>
     );
}
 
export default FormCotizadorIS;