import React from "react";
import { StructuredText } from "react-datocms";

export default function TextStructured({ content }) {
  return (
    <div>
      <div>
        <StructuredText
            data={content}
            renderBlock={({ record }) => {
              if (record.__typename === "DatoCmsImageBlock") {
                return null;
              }
              return (
                <>
                  <p>Don't know how to render a block!</p>
                  <pre>{JSON.stringify(record, null, 2)}</pre>
                </>
              );
            }}
          />
      </div>
    </div>
  );
}