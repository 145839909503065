import React from 'react';
import styled from 'styled-components'
import { InputStyle, TextareaStyle, CheckGeneral, CheckLabel, SendStyle} from '../ui'

const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizador = () => {

    const currentURL = 'utm'

    return ( 
        <SectionStyle>
            <h2>Cotiza ahora</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/cotizador.php"
            >
                <InputStyle
                    type='text'
                    id="nombre"
                    name="nombre"
                    placeholder="Nombre*"
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Particular o empresa'
                        id="tipo"
                        name="tipo"
                    />  
                </GridRow>
                <p>En qué servicio estás interesado</p>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="paneles" 
                        value="Paneles Solares"
                        name="paneles"
                    />                                   
                    <CheckLabel htmlFor="paneles">Paneles Solares</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="ingenieria" 
                        value="Ingeniería Especializada"
                        name="ingenieria"
                    />                                   
                    <CheckLabel htmlFor="ingenieria">Ingeniería Especializada</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="albercas" 
                        value="Albercas"
                        name="albercas"
                    />                                   
                    <CheckLabel htmlFor="albercas">Albercas</CheckLabel>
                </div>                
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="calentadores" 
                        value="Calentadores Solares"
                        name="calentadores"
                    />                                   
                    <CheckLabel htmlFor="calentadores">Calentadores Solares</CheckLabel>
                </div>
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="calderas" 
                        value="Calderas"
                        name="calderas"
                    />                                   
                    <CheckLabel htmlFor="calderas">Calderas</CheckLabel>
                </div> 
                <div>
                    <CheckGeneral 
                        type="checkbox" 
                        id="iluminacion" 
                        value="Iluminación Led"
                        name="iluminacion"
                    />                                   
                    <CheckLabel htmlFor="iluminacion">Iluminación Led</CheckLabel>
                </div>                                                                
                <TextareaStyle
                    type='text'
                    placeholder='Mensaje'
                    id="mensaje"
                    name="mensaje"
                    rows={3}
                />  
                <InputStyle
                    type='text'                    
                    id="utm"
                    name="utm"
                    value={currentURL}
                    disabled
                    style={{ display:'none' }}
                />                
                <br></br>
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
        </SectionStyle>
     );
}

export default FormCotizador;