import React from 'react';
import styled from 'styled-components'
import { InputStyle, TextareaStyle, SendStyle} from '../ui'
import { Radio } from '@nextui-org/react';


const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizadorCS = () => {

    const currentURL = 'utm'  

    return ( 
        <SectionStyle>
            <h2>Calentadores Solares</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/calentadoressolares.php"
            >
                <InputStyle
                    type='text'
                    placeholder='Nombre*'
                    id="nombre"
                    name="nombre"
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Residencial o Comercial*'
                        id="tipo"
                        name="tipo"
                        required
                    />  
                    <InputStyle
                        type='text'
                        placeholder='Ciudad*'
                        id="ciudad"
                        name="ciudad"
                        required
                    />  
                </GridRow>
                <br></br>
                <GridRow>
                    <InputStyle
                        type='text'
                        placeholder='Habitantes*'
                        id="habitaciones"
                        name="habitaciones"
                        required
                    />

                    <InputStyle
                        type='text'
                        placeholder='# Habitaciones*'
                        id="cantidadhabitaciones"
                        name="cantidadhabitaciones"
                        required
                    />   
                </GridRow> 
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input type="radio" value="Tinaco" name="actual" id="actual"/><span> Tinaco</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input type="radio" value="Bomba / Hidroneumático" name="actual" id="actual"/><span> Bomba / Hidroneumático</span>
                </div>                
                <br></br>
                <TextareaStyle
                    type='text'
                    placeholder='Mensaje'
                    id="mensaje"
                    name="mensaje"
                    rows={3}
                />   
                <InputStyle
                    type='text'                    
                    id="utm"
                    name="utm"
                    value={currentURL}
                    disabled
                    style={{ display:'none' }}
                />                               
                <br></br>
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
        </SectionStyle>
     );
}
 
export default FormCotizadorCS;