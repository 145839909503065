import React from 'react';
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { InputStyle, TextareaStyle, SendStyle} from '../ui'

const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizadorPS = () => {

    const currentURL = 'utm' 

    return ( 
        <SectionStyle>
            <h2>Paneles Solares</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/panelessolares.php"
            >
                <InputStyle
                    type='text'
                    placeholder='Nombre*'
                    id="nombre"
                    name="nombre"
                    placeholder='Nombre*'
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Particular o empresa'
                        id="tipo"
                        name="tipo"
                    />  
                </GridRow>
                <br></br>
                <GridRow>
                    <InputStyle
                        type='text'
                        placeholder='¿De qué ciudad eres?*'
                        id="ciudad"
                        name="ciudad"
                        required
                    />
                    <InputStyle
                        type='text'
                        placeholder='¿Cuánto es tu consumo de kW?*'
                        id="kw"
                        name="kw"
                        required
                    />   
                    <InputStyle
                        type='text'
                        placeholder='¿Cuánto es tu tarifa al mes?*'
                        id="tarifa"
                        name="tarifa"
                        required
                    />  
                    <InputStyle
                        type='text'
                        placeholder='¿Cuánto es lo que pagas al mes?*'
                        id="pago"
                        name="pago"
                        required
                    />
                    <InputStyle
                        type='text'                    
                        id="utm"
                        name="utm"
                        value={currentURL}
                        disabled
                        style={{ display:'none' }}
                    />                     
                </GridRow>                
                <TextareaStyle
                    type='text'
                    placeholder='Mensaje'
                    id="mensaje"
                    name="mensaje"
                    rows={3}
                />         
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
            <div style={{textAlign: 'center'}}>
                <StaticImage
                    src="../../images/recibo.jpg"
                    width={500}
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Menú"
                    style={{ marginLeft: '10px'}}
                /> 
            </div>
        </SectionStyle>
     );
}
 
export default FormCotizadorPS;