import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const WaStyle = styled.div`
    height: 80px;
    width: 80px;
    padding: 10px; 
    background-color: #ffffff;  
    border-radius: 50%;
    position: fixed;
    bottom: 2%;
    right: 1%;
    z-index: 9995;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #77b843;
    @media (max-width: 769px) {
        height: 60px;
        width: 60px;
        padding: 10px;    
        bottom: 1%;
        right: 4%;
    }
`;

/*

const CotizaStyle = styled.div`
    height: 80px;
    width: 80px;
    padding: 10px;    
    background-color: #ffffff;  
    border-radius: 50%;
    position: fixed;
    bottom: 13%;
    right: 1%;
    z-index: 9995;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #77b843;
    p{
        color: #77b843 !important;
        margin: 0;
        font-size: .9em;
    }
    @media (max-width: 769px) {
        height: 60px;
        width: 60px;
        padding: 14px;    
        bottom: 12%;
        right: 4%;
        p{
            color: #77b843 !important;
            margin: 0;
            font-size: .6em;
        }
    }
`;
 */

const BtnWa = () => {
    return ( 
        <>
            {
                /*
                <CotizaStyle>
                    <div>
                        <Link to='https://wa.me/525578584764' target='_blank' rel="noreferrer">
                            <StaticImage
                            src="../images/cotizar.png"
                            width={40}
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="WhatsApp"
                            />
                            <p>Cotizar</p>                        
                        </Link>
                    </div>
                </CotizaStyle> 
                 */
            }

            <WaStyle>
                <div>
                    <Link to='https://wa.me/525578584764' target='_blank' rel="noreferrer">
                        <StaticImage
                        src="../images/whatsapp.png"
                        width={40}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="WhatsApp"
                        />
                    </Link>
                </div>
            </WaStyle>                                                             
        </>
     );
}
 
export default BtnWa;