import React from 'react';
import styled from 'styled-components'
import { InputStyle, TextareaStyle, SendStyle} from '../ui'
import { Radio } from '@nextui-org/react';


const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizadorC = () => {

    const currentURL = 'utm'

    return ( 
        <SectionStyle>
            <h2>Calderas</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/calderas.php"                
            >
                <InputStyle
                    type='text'
                    placeholder='Nombre*'
                    id="nombre"
                    name="nombre"
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Particular o empresa'
                        id="tipo"
                        name="tipo"
                    />  
                </GridRow>     
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input type="radio" value="Caldera Doméstica" name="tipocaldera" id="tipocaldera"/><span>Caldera Doméstica</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input type="radio" value="Caldera Residencial" name="tipocaldera" id="tipocaldera"/><span>Caldera Residencial</span>
                </div>
                <TextareaStyle
                    type='text'
                    placeholder='Mensaje'
                    id="mensaje"
                    name="mensaje"
                    rows={3}
                />       
                <InputStyle
                    type='text'                    
                    id="utm"
                    name="utm"
                    value={currentURL}
                    disabled
                    style={{ display:'none' }}
                />                      
                <br></br>
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
        </SectionStyle>
     );
}
 
export default FormCotizadorC;