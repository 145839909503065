import React from 'react';
import styled from 'styled-components'
import TextStructured from './textStructured'
import BtnCotizador from './utils/btnCotizador';

const Back = styled.div`
    background-color: #f6f6f6;
    margin-bottom: 5em;
`;

const SectionStyle = styled.section`
    padding: 2em 1em; 
    font-size: 1.2em;
    text-align: center;
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 4em 4em 4em 4em;
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;
        text-align: left;
    }
`;

const CtaCotizador = ({cta, servicio}) => {

    return ( 
        <Back>
            <SectionStyle>
                <h2><TextStructured content={cta} /></h2>
                <BtnCotizador servicio={servicio}/>
            </SectionStyle>
        </Back>
     );
}
 
export default CtaCotizador;