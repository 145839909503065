import React from 'react';
import styled from 'styled-components'
import { InputStyle, TextareaStyle, SendStyle} from '../ui'

const SectionStyle = styled.div`
    padding: 1em;
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
        padding: 2em;
    }
`;

const FormStyle = styled.form`
    padding-top: 1em;
    display: grid;
    gap: .8em;
`;

const GridRow = styled.div`
    display: grid;
    gap: .8em;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FormCotizadorA = () => {

    const currentURL = 'utm'

    return ( 
        <SectionStyle>
            <h2>Albercas</h2>
            <FormStyle
                method="post" 
                action="https://somosveneno.com/ventas/albercas.php"
            >
                <InputStyle
                    type='text'
                    placeholder='Nombre*'
                    id="nombre"
                    name="nombre"
                    required
                />
                <GridRow>
                    <InputStyle
                        type='email'
                        placeholder='Email*'
                        id="email"
                        name="email"
                        required
                    />

                    <InputStyle
                        type='phone'
                        placeholder='Teléfono*'
                        id="tel"
                        name="tel"
                        required
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Particular o empresa'
                        id="tipo"
                        name="tipo"
                    />  
                </GridRow>
                <br></br>
                <p>Medidas de la alberca</p>
                <GridRow>
                    <InputStyle
                        type='text'
                        placeholder='Largo'
                        id="largo"
                        name="largo"
                    />

                    <InputStyle
                        type='text'
                        placeholder='Ancho'
                        id="ancho"
                        name="ancho"
                    />   

                    <InputStyle
                        type='text'
                        placeholder='Profundidad'
                        id="profundidad"
                        name="profundidad"
                    />  
                </GridRow> 
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio" value="Interior/ Techada" name="tipoalberca" id="tipoalberca"/><span> Interior / Techada</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="Exterior / Abierta" name="tipoalberca" id="tipoalberca"/><span>Exterior / Abierta</span>
                </div>
                <br></br>
                <p>Distancia aproximada entre boiler y los paneles</p>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="1-10 mts" name="distancia" id="distancia"/><span>1-10 mts</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="11-20 mts" name="distancia" id="distancia"/><span>11-20 mts</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="21-30 mts" name="distancia" id="distancia"/><span>21-30 mts</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="31-40 mts" name="distancia" id="distancia"/><span>31-40 mts</span>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input type="radio"  value="41-50 mts" name="distancia" id="distancia"/><span>41-50 mts</span>
                </div>
                <br></br>
                <TextareaStyle
                    type='text'
                    placeholder='Mensaje'
                    id="mensaje"
                    name="mensaje"
                    rows={3}
                />       
                <InputStyle
                    type='text'                    
                    id="utm"
                    name="utm"
                    value={currentURL}
                    disabled
                    style={{ display:'none' }}
                />                           
                <br></br>
                <SendStyle
                    type='submit'
                    value='Cotizar'
                />                  
            </FormStyle>
        </SectionStyle>
     );
}
 
export default FormCotizadorA;