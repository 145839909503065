import React from 'react';
import ReactPlayer from 'react-player'

const Video = ({urlvideo}) => {
    return (         
        <ReactPlayer
            url={urlvideo}
            className='react-player'            
            width='100%'
            height='100%'
            controls='true'
        />
     );
}
 
export default Video;


